import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CountryAutoComplete } from '../../countries/CountryAutoComplete';
import { CurrencyAutoComplete } from '../../currencies/CurrencyAutoComplete';

export const GeneralInfoContainer = ({ disabled, operator, onChange }: any) => {
  const [fields, setFields] = useState<any>({});

  useEffect(() => {
    setFields({
      country_id: operator?.country?.primary_id,
      currency_id: operator?.currency?.primary_id,
      ...operator
    });
  }, [operator]);

  const handleChange = (
    value: any,
    field: string,
    displayName: string = '',
    displayValue: string = '',
    isNumber: boolean = false
  ) => {
    if (isNumber) {
      value = Number(value);
    }
    setFields({
      ...fields,
      [field]: value
    });
    onChange(value, field, displayName, displayValue);
  };

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-1"
            label="Name"
            autoComplete="off"
            value={fields?.name || ''}
            onChange={(event: any) => handleChange(event.target.value, 'name')}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <CountryAutoComplete
            disabled={operator?.primary_id == null ? false : true}
            label="Country"
            id={fields?.country_id}
            onChange={(country: any) =>
              handleChange(country.primary_id, 'country_id', 'Country', country.name)
            }
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <CurrencyAutoComplete
            disabled={disabled}
            label="Currency"
            id={fields?.currency_id}
            onChange={(currency: any) =>
              handleChange(
                currency.primary_id,
                'currency_id',
                'Currency',
                `${currency.country_code} - ${currency.currency}`
              )
            }
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="protocol-select-label">Protocol</InputLabel>
            <Select
              labelId="access-type-select-label"
              id="protocol"
              disabled={disabled}
              onChange={(event: any) => handleChange(event.target.value, 'protocol', 'Protocol')}
              value={fields?.protocol || 'NONE'}
              label="Protocol"
            >
              <MenuItem value="OCPI">Open Charge Point Interface (OCPI)</MenuItem>
              <MenuItem value="OICP">Open InterCharge Protocol (OICP)</MenuItem>
              <MenuItem value="NONE">NONE</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            disabled={disabled}
            id="party_identity"
            label="Party identity"
            placeholder="NO*KPL (OCPI)/FI*001 (OICP)"
            value={fields?.party_identity || ''}
            onChange={(event: any) => handleChange(event.target.value, 'party_identity')}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="evse_prefixes"
            label="Evse prefiesx"
            placeholder="NO*KPL,NO*CKE"
            value={fields?.evse_prefixes || ''}
            onChange={(event: any) => handleChange(event.target.value, 'evse_prefixes')}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
              fullWidth
              disabled={disabled}
              id="supplier_discount"
              label="Supplier discount, 1.08 for 8% added discount"
              placeholder="1.08 for 8% added supplier discount"
              value={fields?.supplier_discount || ''}
              type="number"
              onChange={(event: any) =>
                  handleChange(
                      event.target.value,
                      'supplier_discount',
                      'Supplier discount',
                      event.target.value || '',
                      true
                  )
              }
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="surcharge"
            label="Surcharge, 1.07 for 7% added surcharge"
            placeholder="1.07 for 7% added surcharge"
            value={fields?.surcharge || ''}
            type="number"
            onChange={(event: any) =>
              handleChange(
                event.target.value,
                'surcharge',
                'Surcharge',
                event.target.value || '',
                true
              )
            }
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
              fullWidth
              disabled={disabled}
              id="per_kwh_discount_excl_vat"
              label="Per kwh discount excl vat"
              placeholder="E.g. 0.5 for reduscing OCPI tariff price from 5kr/kwh to 4.5kr/kwh"
              value={fields?.per_kwh_discount_excl_vat || ''}
              type="number"
              onChange={(event: any) =>
                  handleChange(
                      event.target.value,
                      'per_kwh_discount_excl_vat',
                      'Per kwh discount excl vat',
                      event.target.value || '',
                      true
                  )
              }
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="app_placeholder"
            label="App placeholder"
            placeholder="123401"
            value={fields?.app_placeholder || ''}
            type="text"
            onChange={(event: any) =>
              handleChange(
                event.target.value,
                'app_placeholder',
                'App placeholder',
                event.target.value || ''
              )
            }
          />
          <TextField
            fullWidth
            disabled={disabled}
            id="app_prefix-field"
            label="App prefix"
            placeholder="E"
            value={fields?.app_prefix || ''}
            type="text"
            onChange={(event: any) =>
              handleChange(event.target.value, 'app_prefix', 'App prefix', event.target.value || '')
            }
          />
        </Stack>
      </Stack>
    </Card>
  );
};
