import { Icon } from '@iconify/react';
import ClearIcon from '@mui/icons-material/Clear';
import { styled, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: 'black',
  height: '1.5rem',
  width: '1.5rem',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

const DeleteIcon = styled(ClearIcon)(({ theme }) => ({
  color: 'black',
  height: '1.5rem',
  width: '1.5rem',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.error.main
  }
}));

const Action = ({ id, deleteCallback, canEdit }: any) =>
  id ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%'
      }}
    >
      <Link
        to={`/dashboard/operators/edit/${id}`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Tooltip title="View details">
          <StyledIcon icon="bx:show" />
        </Tooltip>
      </Link>
      {canEdit && (
        <DeleteIcon
          style={{ cursor: 'pointer' }}
          color="action"
          onClick={() => deleteCallback(id)}
        />
      )}
    </div>
  ) : (
    <div />
  );

export const gridColumns: GridColDef[] = [
  {
    field: 'actions',
    headerName: ' ',
    description: 'Actions',
    width: 80,
    sortable: false,
    renderCell: (params: any) => Action(params.value)
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 200,
    renderCell: (params: any) => (params.value ? `${params.value.flag} ${params.value.name}` : '')
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 200,
    renderCell: (params: any) => (params.value ? `${params.value.currency}` : '')
  },
  {
    field: 'party_identity',
    headerName: 'Party identity',
    width: 200
  },
  {
    field: 'evse_prefixes',
    headerName: 'Evse prefixes',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.join(', ') : '')
  },
  {
    field: 'can_charge_with_elton',
    headerName: 'C. Elton',
    description: 'Can charge with Elton',
    type: 'boolean',
    width: 140
  },
  {
    field: 'only_use_base',
    headerName: 'Only base',
    description: 'Only use base',
    type: 'boolean',
    width: 140
  },
  {
    field: 'supplier_discount',
    headerName: 'Supplier discount',
    width: 200,
    renderCell: (params: any) => (params.value ? `${params.value}` : '')
  },
  {
    field: 'surcharge',
    headerName: 'Surcharge',
    width: 200,
    renderCell: (params: any) => (params.value ? `${params.value}` : '')
  },
  {
    field: 'per_kwh_discount_excl_vat',
    headerName: 'Price kwh discount excl vat',
    width: 200,
    renderCell: (params: any) => (params.value ? `${params.value}` : '')
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 170,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  { field: 'primary_id', headerName: 'Id', width: 350 }
];
